import React from "react"
import Header from "../components/header"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100vw',
    height:'100vh',
    display: 'flex',
    flexDirection:'column',
  },
  body:{
    flexGrow:1,
    width:'100%',
    height:'100%',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
  },
}));

export default function Home() {
  const classes = useStyles();

  return(
    <div className={classes.root}>
      <Header/>
      <div className={classes.body}>
        <Typography
          align="center"
          variant="h6"
          color="inherit"
        >
          Sorry, this page does not exist.
        </Typography>
      </div>
    </div>
  );
}
